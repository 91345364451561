import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../Errors';
import SystemAlerts from '../Alerts';
import * as ROUTES from '../../constants/routes';

const HomePage = lazy(() => import('../Home'));
const LoginPage = lazy(() => import('../SignIn'));
const LogoutPage = lazy(() => import('../Signout'));
//import AdminPage from '../Admin';
const UserDashboard = lazy(() => import('../Users'));
const UserAdminDashboard = lazy(() => import('../Users/adminUserPannel'));
const CoursesDashboard = lazy(() => import('../Courses'));
const EditCoursesDashboard = lazy(() => import('../Courses/editCourse'));
const EditCoursesPageDashboard = lazy(() => import('../Courses/editPage'));
const CompanyPageBase = lazy(() => import('../Companies'));
const EditCompanyForm = lazy(() => import('../Companies/editCompany'));
const TechHomePage = lazy(() => import('../Technologies'));
const PromoDashboard = lazy(() => import('../Promos'));
const QuizDashboard = lazy(() => import('../Quizzes'));
const EditQuizPage = lazy(() => import('../Quizzes/editQuiz'));
const LoadingBlock = lazy(() => import('../Loading'));
const UserCourseReviewPage = lazy(() => import('../Courses/userLists-v2'));
const CertAdminDashboard = lazy(() => import('../Certs'));
const TierHomePage = lazy(() => import('../Tiers'));
const ResourceLibraryPageBase = lazy(() => import('../ResourceLibrary'));
const OrderProgramsHomePage = lazy(() => import('../OrderingPrograms'));
const NationalAccountsHomePage = lazy(() => import('../NationalAccounts'));
const OrderingHomePageBase = lazy(() => import('../OrderSystem'));
const ViewAllSubmittedOrdersPage = lazy(() => import('../OrderSystem/viewAllSubmittedOrders-v2'));
const ForgotPasswordForm = lazy(() => import('../SignIn/forgotPassword'));
const EditOrderView = lazy(() => import('../OrderSystem/editViewLandingPage'));
const CompanyWarrantiesForm = lazy(() => import('../Companies/companyWarranty'));
const SalesRepPage = lazy(() => import('../Reps/salesRepPage'));
const MessagingSystem = lazy(() => import('../Messaging'));
const TrainingSystem = lazy(() => import('../Training'));
const ReviewClinic = lazy(() => import('../ReviewClinics'));
const ROI_Calc = lazy(() => import('../ROI'));

const App = () => (
  <Router>
    <div>
      <Suspense fallback={<LoadingBlock />}>
        <Switch>
          <ErrorBoundary>
            <Route exact path={ROUTES.LANDING} component={HomePage} />
            <Route exact path={ROUTES.LOGIN} component={LoginPage} />
            <Route exact path={ROUTES.LOGOUT} component={LogoutPage} />
            <Route exact path={ROUTES.USER} component={UserAdminDashboard} />
            <Route exact path={ROUTES.USERS} component={UserDashboard} />
            <Route exact path={ROUTES.COURSES} component={CoursesDashboard} />
            <Route exact path={ROUTES.EDIT_COURSE} component={EditCoursesDashboard} />
            <Route exact path={ROUTES.EDIT_COURSE_PAGE} component={EditCoursesPageDashboard} />
            <Route exact path={ROUTES.COMPANY} component={CompanyPageBase} />
            <Route exact path={ROUTES.EDIT_COMPANY_ID} component={EditCompanyForm} />
            <Route exact path={ROUTES.TECH} component={TechHomePage} />
            <Route exact path={ROUTES.PROMOS} component={PromoDashboard} />
            <Route exact path={ROUTES.QUIZZES} component={QuizDashboard} />
            <Route exact path={ROUTES.EDIT_QUIZZES_PAGE} component={EditQuizPage} />
            <Route exact path={ROUTES.COURSE_DATA} component={UserCourseReviewPage} />
            <Route exact path={ROUTES.CERTS} component={CertAdminDashboard} />
            <Route exact path={ROUTES.TIERS} component={TierHomePage} />
            <Route exact path={ROUTES.RESOURCE_LIBRARY} component={ResourceLibraryPageBase} />
            <Route exact path={ROUTES.NATIONAL_ACCOUNTS} component={NationalAccountsHomePage} />
            <Route exact path={ROUTES.ORDER_PROGRAMS} component={OrderProgramsHomePage} />
            <Route exact path={ROUTES.ORDERS} component={OrderingHomePageBase} />
            <Route exact path={ROUTES.REVIEW_ORDERS} component={ViewAllSubmittedOrdersPage} />
            <Route exact path={ROUTES.FORGOTTEN_PASSWORD} component={ForgotPasswordForm} />
            <Route exact path={ROUTES.EDIT_ORDERS} component={EditOrderView} />
            <Route exact path={ROUTES.WARRANTIES} component={CompanyWarrantiesForm} />
            <Route exact path={ROUTES.SALES_REPS} component={SalesRepPage} />
            <Route exact path={ROUTES.MESSAGING} component={MessagingSystem} />
            <Route exact path={ROUTES.TRAINING} component={TrainingSystem} />
            <Route exact path={ROUTES.REVIEW_CLINIC} component={ReviewClinic} />
            <Route exact path={ROUTES.ROI} component={ROI_Calc} />
          </ErrorBoundary>
        </Switch>
      </Suspense>
      <SystemAlerts />
    </div>
  </Router>
);

export default App;
